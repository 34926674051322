<template>
  <div>
    <content-box title="Coaching Staff" class="col-lg-9 col-xl-7">
      <div v-if="loading" class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <template v-else>
        <h4>Program Director</h4>
        <div class="row justify-content-center">
          <contact-card
            v-for="director in directors"
            :key="director.id"
            class="m-2"
            :contact="director"
          >
          </contact-card>
        </div>

        <hr />

        <h4>Coaches</h4>
        <div class="row justify-content-center">
          <contact-card
            v-for="coach in coaches"
            :key="coach.id"
            class="m-2"
            :contact="coach"
          >
          </contact-card>
        </div>
      </template>
    </content-box>

    <content-box class="col-lg-9 col-xl-7">
      <p>
        A 100% volunteer organization, the program is only possible through the
        hard work and dedication of our coaches and parents
      </p>
      <p>
        If you would like to volunteer as a coach, please contact us at
        <a :href="mailto">{{ mailto }}</a>
      </p>
    </content-box>
  </div>
</template>

<script>
import apiService from '@/services/apiService'

import contactCard from '@/components/contactCard'
import contentBox from '@/components/contentBox'

export default {
  name: 'coaches',
  components: {
    contactCard,
    contentBox,
  },

  data() {
    return {
      loadingDirectors: false,
      loadingCoaches: false,
      directors: [],
      coaches: [],
      mailto: 'coaches@haverhillhammers.org',
    }
  },

  created() {
    this.fetchDirectors()
    this.fetchCoaches()
  },

  computed: {
    loading() {
      return this.loadingDirectors || this.loadingCoaches
    },
  },

  methods: {
    fetchDirectors() {
      this.loadingCoaches = true

      apiService
        .getDirectors()
        .then((yay) => {
          this.loadingCoaches = false
          this.directors = yay
        })
        .catch(function () {
          this.loadingCoaches = false
        })
    },

    fetchCoaches() {
      this.loadingDirectors = true

      apiService
        .getCoaches()
        .then((yay) => {
          this.loadingDirectors = false
          this.coaches = yay
        })
        .catch(function () {
          this.loadingDirectors = false
        })
    },
  },
}
</script>

<style scoped></style>
